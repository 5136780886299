<template>
    <div class="page">
        <div class="content">
            <el-form inline class="tb-form" label-width="90" label-position="left">
                <el-form-item label="昵称：">
                    <el-input v-model="search.nick_name"></el-input>
                </el-form-item>
                <el-form-item label="关联话题：">
                    <el-select v-model="search.topic_id" class="section-style">
                        <el-option label="全部" :value="null"/>
                        <el-option v-for="item in topicList" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="发布时间：">
                    <el-date-picker
                            class="section-style"
                            v-model="release_date"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="search.status" class="section-style">
                        <el-option label="全部" :value="null"/>
                        <el-option label="已开始" :value="1"/>
                        <el-option label="已下架" :value="0"/>
                        <el-option label="已开奖" :value="2"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="resetParams">重置</el-button>
                </el-form-item>
            </el-form>

            <div class="tablebox">
                <el-table :data="tableData" class="tb-table" @row-dblclick="jumpToContent">
                    <el-table-column label="ID" prop="id" width="60"/>
                    <el-table-column label="昵称" prop="nick_name" show-overflow-tooltip/>
                    <el-table-column label="内容">
                        <template slot-scope="scope">
                            <el-popover
                                    width="320"
                                    placement="top"
                                    trigger="hover"
                                    :content="scope.row.content">
                                <div slot="reference" class="ellipsis-one">{{scope.row.content}}</div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="缩略图" width="120px">
                        <template slot-scope="scope">
                            <img :src="viewContent(scope.row.content_list[0].content)" style="width:107px;height: 64px; object-fit:cover" v-if="scope.row.content_list[0].content_type == 1">
                            <video :src="viewContent(scope.row.content_list[0].content)"  controls="controls" style="width:107px;height: 64px;" v-if="scope.row.content_list[0].content_type == 2"></video>
                        </template>
                    </el-table-column>
                    <el-table-column label="关联话题" prop="content" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-for="topic in scope.topic_list" :key="topic.id">{{topic.title}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="发布时间" prop="created_at" show-overflow-tooltip/>
                    <el-table-column label="状态" width="60">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status===0">下架</span>
                            <span v-else>上架</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200px">
                        <template slot-scope="scope">
                            <el-button type="text" @click="audioContent(scope.row.id)">审核内容</el-button>
                            <el-button v-if="scope.row.status===1" type="text" @click="offShelf(scope.row.id)">违规下架
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        class="tb-pagination"
                        layout="prev, pager, next"
                        :current-page.sync="page.no + 1"
                        :page-size="page.size"
                        :total="page.total"
                        @current-change="pageChange"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"FeedList",
    data(){
        return {
            search: {
                topic_id: null,
                nick_name: '',
                start_time: "",
                end_time: "",
                status: null,
            },
            release_date: ["", ""],
            tableData: [],
            topicList: [],
            page: {
                no: 0,
                size: 10,
                total: 0
            },
        }
    },
    methods:{
        pageChange(no) {
            this.page.no = no - 1
            this.getFreeList()
        },
        onSearch() {
            this.page.no = 0
            this.search.start_time = this.release_date[0]
            this.search.end_time = this.release_date[1]
            this.getFreeList()
        },
        resetParams() {
            this.search = {
                topic_id: null,
                nick_name: '',
                status: null,
            }
            this.release_date = ["", ""]
        },
        async getFreeList(){
            const url = "admin/feed/list"
            const params = {
                page_no: this.page.no,
                page_size: this.page.size,
                ...this.search
            }
            let data = await this.$https.get(url, {params})
            if (data) {
                this.tableData = data.list
                this.page.total = data.total_count
            }
        },
        async getTopicList(){
            const url="admin/feed/topic/list"
            const params = {
                page_no:0,
                page_size:200
            }
            let data = await this.$https.get(url, {params})
            if (data) {
                this.topicList = data.list
            }
        },
        viewContent(str){
            const strObj = JSON.parse(str);
            return strObj.url;
        },
        jumpToContent(row) {
            this.audioContent(row.id)
        },
        audioContent(id){
            this.$router.push(`/feed/audit?id=${id}`)
        },
        offShelf(id){
            this.$confirm('确认要下架这条内容吗?', '下架后小程序前端界面将不展示该条内容。', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const url = `admin/feed/modify/status`
                    let data = await this.$https.post(url, {id: id, status : 0})
                    if (data) {
                        this.$message.success('下架成功！')
                        this.onSearch()
                    }
                });
        }
    },
    mounted() {
        let path = this.$route.path
            if (path.indexOf('/') === 0) path = path.substring(1)
            const currentPage = {
                link: path,
                name: '用户消息流列表'
            }
            this.$store.commit('changeRoute', currentPage)
            this.getTopicList()
            this.getFreeList()
    }
}
</script>